import slugify from "slugify";

module.exports = {
  getModuleList: (modules) => {
    const moduleList = [];
    modules &&
      modules.forEach((component) => {
        const { subNavLabel } = component.fields;
        const {
          contentType: {
            sys: { id },
          },
        } = component.sys;
        if (!subNavLabel) {
          return null;
        }
        moduleList.push({
          contentType: id,
          label: subNavLabel,
          slug: slugify(subNavLabel).toLowerCase(),
        });
      });
    return moduleList;
  },
};
